// Signup.js
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import Error from "../components/Error";
import { useHistory } from "react-router-dom";
import Home_Navbar from "../components/Home_Navbar";
import { BASE_URL } from "../function/Config";
import ReCAPTCHA from "react-google-recaptcha";

export default function Signup() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [error, setError] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Replace 'YOUR_SITE_KEY' with your actual reCAPTCHA site key
  const RECAPTCHA_SITE_KEY = "6Ld9hnYqAAAAADW4ClI8e4rV-21BcsEO5777XChq"; // Leave blank for you to fill in

  const generatePassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    // Set the generated password in the form
    setValue("password", password);
    // Make the password visible
    setPasswordInputType("text");
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const Submit = async (data) => {
    if (!recaptchaValue) {
      setError("Please complete the reCAPTCHA verification.");
      return;
    }

    try {
      // Include the reCAPTCHA token in the data sent to the backend
      const requestData = {
        ...data,
        recaptchaToken: recaptchaValue,
      };

      // Ensure the URL is correctly formatted
      let url = `${BASE_URL}/auth/signup`;
      url = url.replace(/([^:]\/)\/+/g, "$1");

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      // Check if the response status is OK (status code 2xx)
      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || "Signup failed");
        return;
      }

      const feedback = await response.json();
      sessionStorage.setItem("Token", feedback.access_token);
      if (feedback.user_id !== undefined) {
        history.push(`/dashboard/${feedback.user_id}`);
      }
    } catch (error) {
      console.error("There was an error!", error);
      setError("Network error or server is not responding");
    }
  };

  const handleCloseError = () => {
    setError("");
  };

  return (
    <>
      <Home_Navbar />
      <section className="bg-gray-200 dark:bg-gray-900 min-h-screen flex items-center justify-center p-4">
        <div className="flex flex-col md:flex-row w-full max-w-6xl">
          <div className="flex-1 flex items-center justify-end bg-gray-800 text-white p-6 rounded-lg md:rounded-r-none">
            <div className="flex flex-col">
              <h1 className="text-2xl sm:text-3xl md:text-4xl flex justify-center items-center text-center font-bold mb-6">
                Start Building with Your Free Plan
              </h1>
              <ul className="text-base sm:text-lg px-8 py-4">
                <li className="py-1">AI-Powered Video Generator</li>
                <li className="py-1">High-Quality Spoken Audio</li>
                <li className="py-1">Voice Cloning Technology</li>
                <li className="py-1">Virtual Presenter Feature</li>
                <li className="py-1">Enhanced Content Delivery</li>
              </ul>
            </div>
          </div>
          <div className="flex-1 flex flex-col bg-white dark:bg-gray-800 text-gray-900 p-6 rounded-lg md:rounded-l-none">
            {error && <Error content={error} onClose={handleCloseError} />}
            <div className="rounded-lg shadow-lg p-6 w-full max-w-md bg-white dark:bg-gray-800">
              <div className="space-y-4">
                <form
                  onSubmit={handleSubmit((data) => {
                    Submit(data);
                  })}
                  className="space-y-6"
                  action="#"
                >
                  <div>
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Name
                    </label>
                    <input
                      type="text"
                      {...register("name", { required: "This is required." })}
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 dark:text-gray-100 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    />
                    <p className="text-xs text-red-600">{errors.name?.message}</p>
                  </div>
                  <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Email
                    </label>
                    <input
                      type="email"
                      {...register("email", { required: "This is required." })}
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 dark:text-gray-100 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    />
                    <p className="text-xs text-red-600">{errors.email?.message}</p>
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Password
                      </label>
                      <button
                        type="button"
                        onClick={generatePassword}
                        className="text-sm font-medium text-primary-600 hover:underline"
                      >
                        Generate Strong Password
                      </button>
                    </div>
                    <input
                      type={passwordInputType}
                      {...register("password", {
                        required: "This is required.",
                        minLength: { value: 8, message: "Minimum length is 8 characters" },
                      })}
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 dark:text-gray-100 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    />
                    <p className="text-xs text-red-600">{errors.password?.message}</p>
                  </div>

                  {/* Terms and Conditions Checkbox */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="terms"
                      {...register("terms", { required: "You must agree to the terms and conditions." })}
                      className="mr-2"
                    />
                    <label htmlFor="terms" className="text-sm text-gray-900 dark:text-white">
                      I agree to the{" "}
                      <button type="button" onClick={openModal} className="text-primary-600 hover:underline">
                        Terms and Conditions
                      </button>
                    </label>
                  </div>
                  {errors.terms && <p className="text-xs text-red-600">{errors.terms.message}</p>}

                  {/* reCAPTCHA */}
                  <div>
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onRecaptchaChange}
                    />
                  </div>

                  {error && <p className="text-red-500 text-sm">{error}</p>}

                  <button
                    type="submit"
                    className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-150 ease-in-out"
                  >
                    Create an account
                  </button>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Already have an account?{" "}
                    <a href="/signin" className="text-primary-600 hover:underline">
                      Sign in here
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Terms and Conditions */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-screen overflow-y-auto relative">
            <div className="p-6">
              {/* Close Button at Top Right */}
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none"
                aria-label="Close"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

              <h2 className="text-2xl font-bold mb-4 text-center">Terms and Conditions</h2>
              <div className="text-sm text-gray-700 dark:text-gray-300 space-y-4">
                <p>
                  Welcome to <strong>Sregen.AI</strong> (hereinafter referred to as "the Website"). By registering and using the services provided by Sregen.AI, you agree to the following Terms and Conditions ("Agreement"). Please read carefully before proceeding, as this Agreement outlines your rights and responsibilities in using our services, which include the generation of AI-based content that clones user voices and appearances.
                </p>

                <h3 className="mt-4 font-bold">1. Eligibility</h3>
                <p>
                  You must be at least 18 years of age or have consent from a legal guardian to use the Website. You confirm that all information provided during registration is accurate, complete, and current.
                </p>

                <h3 className="mt-4 font-bold">2. Account Security</h3>
                <p>
                  You are responsible for maintaining the confidentiality of your account credentials. You agree to take responsibility for all activities that occur under your account and to notify us immediately of any unauthorized use.
                </p>

                <h3 className="mt-4 font-bold">3. Permitted Use</h3>
                <p>
                  You agree to use the Website’s services solely for lawful purposes.
                </p>
                <p>
                  You are strictly prohibited from using any content generated on this Website for illegal, harmful, or malicious activities, including but not limited to:
                </p>
                <ul className="list-disc list-inside">
                  <li>Infringing on the privacy or image rights of others</li>
                  <li>Promoting or disseminating hate speech or discriminatory content</li>
                  <li>Engaging in fraud, defamation, or other illegal conduct</li>
                </ul>

                <h3 className="mt-4 font-bold">4. Intellectual Property</h3>
                <p>
                  Content generated by the Website is owned by you, but by using our services, you grant Sregen.AI the right to use your generated content solely to improve and optimize our services.
                </p>
                <p>
                  You agree not to infringe upon the intellectual property rights of Sregen.AI or any third party.
                </p>

                <h3 className="mt-4 font-bold">5. Privacy Policy</h3>
                <p>
                  Your personal information will be handled in accordance with our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and safeguard your data.
                </p>

                <h3 className="mt-4 font-bold">6. Limitation of Liability</h3>
                <p>
                  Sregen.AI shall not be held liable for any direct, indirect, incidental, special, or consequential damages arising from the use or inability to use our services.
                </p>

                <h3 className="mt-4 font-bold">7. Termination</h3>
                <p>
                  Sregen.AI reserves the right to suspend or terminate your account and access to our services if you violate any terms outlined in this Agreement.
                </p>

                <h3 className="mt-4 font-bold">8. Modifications</h3>
                <p>
                  Sregen.AI reserves the right to modify these Terms and Conditions at any time. Any changes will take effect immediately upon posting. Continued use of the Website constitutes acceptance of the revised terms.
                </p>

                <h3 className="mt-4 font-bold">9. Governing Law</h3>
                <p>
                  This Agreement is governed by and construed in accordance with applicable laws.
                </p>

                <h3 className="mt-4 font-bold">10. Contact Us</h3>
                <p>
                  For any questions or assistance, please reach out to our customer support team.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
