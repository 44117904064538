// Video.js
import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import { SelectedFileContext } from '../components/SelectedFileContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from "../function/Config";
import SystemError from "../components/SystemError";

export default function Video() {

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const params = useParams();

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [files, setFiles] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const filesPerPage = 5;

    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [iframeSrc, setIframeSrc] = useState('');
    const { selectedFiles, selectFile } = useContext(SelectedFileContext);
    const [modelFilter, setModelFilter] = useState('All');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const select = (file) => {
        if (selectedFiles?.video?.id === file.id) {
            selectFile('video', null);
            setSelectedFile(null);
        } else {
            selectFile('video', file);
            setSelectedFile(file);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        let url = `${BASE_URL}/avatar-tasks/${params.userId}/done?exclude_deleted=true`;
        if (modelFilter !== 'All') {
            url += `&model=${encodeURIComponent(modelFilter)}`;
        }

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setFiles(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });
    }, [params.userId, modelFilter]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const filters = ['All', 'Fast Talking-head Model', 'High-Resolution Talking-head Model', 'Gaussian Splatter Talking-head Model', 'Gaussian Splatter Talking-head Full-Body Model', 'NERF Talking-head Model'];
    const handleFileClick = (fileUrl) => {
        setIframeSrc(fileUrl);
    };

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            {showModal && <SystemError onClose={() => setShowModal(false)} />}
            <div className="p-4 sm:ml-64">
                {isLoading ? (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-50 dark:bg-gray-800">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                        </div>
                    </div>
                ) : (
                    <div className="mx-auto mt-6"> {/* 调整 mt-8 为 mt-6 */}
                        {uploadSuccess && (
                            <div id="toast-success"
                                style={{
                                    width: '80%',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }}
                                className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert">
                                <div
                                    className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    <span className="sr-only">Check icon</span>
                                </div>
                                <div className="ms-3 text-sm font-normal">
                                    {selectedFile ? `${selectedFile.name} upload successfully.` : "Upload successfully."}
                                </div>
                                <button onClick={() => setUploadSuccess(false)} type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#toast-success" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                </button>
                            </div>
                        )}

                        <div className="flex flex-col lg:flex-row justify-center items-center gap-3 mt-20"> {/* 调整 gap-5 为 gap-3, mt-20 为 mt-2 */}
                            {/* 左侧：模型列表 */}
                            <div className="w-full lg:w-2/5 flex flex-col bg-white rounded-lg shadow-lg p-4 dark:bg-gray-800 dark:border-gray-700"> {/* 调整 p-5 为 p-4 */}
                                {/* 标题 */}
                                <h2 className="text-xl text-center font-semibold text-gray-800 dark:text-white mb-1">Model List</h2> {/* 调整 mb-2 为 mb-1 */}

                                {/* 文件列表 */}
                                <div className="overflow-auto min-h-[250px]"> {/* 调整 min-h-[500px] 为 min-h-[400px] */}
                                    {/* 文件列表 */}
                                    <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                                        {currentFiles.map((file) => (
                                            <li key={file.uuid} className="py-2 sm:py-3 hover:bg-gray-100 dark:hover:bg-gray-700"> {/* 调整 py-3 sm:py-4 为 py-2 sm:py-3 */}
                                                <div className="flex items-center justify-between">
                                                    {/* 文件信息 */}
                                                    <button onClick={() => handleFileClick(`${BASE_URL}/avatar-tasks/${file.id}`)} className="hover:text-blue-500 flex items-center">
                                                        <FontAwesomeIcon icon={faVideo} />
                                                        <span className="ml-2 truncate" style={{ maxWidth: '150px' }} title={file.filename}>
                                                            {file.filename.length > 15 ? `${file.filename.substring(0, 15)}...` : file.filename}
                                                        </span>
                                                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                                            {file.model}
                                                        </span>
                                                    </button>
                                                    {/* 选择文件按钮 */}
                                                    <button onClick={() => select(file)} className="text-indigo-600 hover:text-indigo-800">
                                                        <FontAwesomeIcon icon={selectedFile === file || selectedFiles?.video?.id === file.id ? faCheckCircle : faCircle} className={selectedFile === file || selectedFiles?.video?.id === file.id ? "text-green-500" : "text-gray-400"} />
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* 提示文本 */}
                                <div className="text-center text-sm text-gray-500 my-1"> {/* 调整 my-2 为 my-1 */}
                                    If you can't find some models, please check at{' '}
                                    <a
                                        href={`https://demo.sregen.ai/history/${params.userId}/avatar`}
                                        className="text-blue-500 underline"
                                    >
                                        history page
                                    </a>.
                                </div>

                                {/* 分页和模型筛选按钮 */}
                                <div className="flex justify-center items-center space-x-2 mt-2"> {/* 调整 space-x-4 为 space-x-2, mt-4 为 mt-2 */}
                                    {/* 分页按钮 */}
                                    <div className="pagination flex items-center space-x-1"> {/* 调整 space-x-2 为 space-x-1 */}
                                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}
                                            className="mx-1 px-3 py-1 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50">Previous
                                        </button>
                                        <p className="text-sm font-medium text-gray-900 dark:text-white">{currentPage}</p>
                                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage >= Math.ceil(files.length / filesPerPage)}
                                            className="mx-1 px-3 py-1 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50">Next
                                        </button>
                                    </div>
                                    {/* 模型筛选按钮 */}
                                    <div className="relative">
                                        <button onClick={toggleDropdown}
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                            type="button">
                                            {modelFilter} Models
                                            <svg className="w-2 h-2 ml-2" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                            </svg>
                                        </button>
                                        {dropdownOpen && (
                                            <div className="absolute z-10 w-40 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 mt-1">
                                                <ul className="p-2 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                                                    {filters.map(filter => (
                                                        <li key={filter}>
                                                            <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                                <input id={`default-radio-${filter}`} type="radio" value={filter} name="default-radio" checked={modelFilter === filter} onChange={() => setModelFilter(filter)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                <label htmlFor={`default-radio-${filter}`} className="ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                                                                    {filter}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* 分割线 */}
                            <div className="border-t border-gray-300 dark:border-gray-700 my-2"></div> {/* 减少上下间距 */}

                            {/* 右侧：视频播放器 */}
                            <div className="flex-grow flex flex-col w-full text-center lg:w-3/5">
                                <div className="flex-grow rounded-lg shadow-lg overflow-hidden mb-2 mt-2"> {/* 调整 mb-4 mt-6 为 mb-2 mt-2 */}
                                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-1">Talking-head Player</h2> {/* 调整 mb-4 为 mb-1 */}
                                    <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">Click on a model name on the list to play the video.</p> {/* 添加 mb-2 */}
                                    <iframe src={iframeSrc} title="Video Preview" className="w-full h-[250px]" allow="autoplay; encrypted-media" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}
