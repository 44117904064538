// Dropzone.jsx
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useParams, useLocation } from "react-router-dom";
import { BASE_URL } from "../function/Config";
import axios from 'axios';

const Dropzone = ({ className, onResponse, instructionText, onProgress, accept, maxSize }) => {

    const [isUploading, setIsUploading] = useState(false);   // 正在上传
    const [uploadError, setUploadError] = useState(null);    // 上传错误

    const params = useParams();
    const location = useLocation();

    const regex = /^\/([^\/]+)\/\d+$/;
    const match = location.pathname.match(regex);
    const pathSegment = match ? match[1] : '';

    const defaultMaxSize = 20 * 1024 * 1024; // 20 MB

    // 上传文件的函数
    const uploadFiles = async (filesToUpload) => {
        if (!filesToUpload?.length) return;

        const formData = new FormData();
        filesToUpload.forEach(file => formData.append('file', file));

        const URL = `${BASE_URL}/${pathSegment}s/${params.userId}/upload`;
        const token = sessionStorage.getItem('Token');

        try {
            setIsUploading(true);
            setUploadError(null);

            const response = await axios.post(URL, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                onUploadProgress: progressEvent => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (typeof onProgress === 'function') {
                        onProgress(progress); // 将进度传递给父组件
                    }
                },
            });

            const feedback = response.data;

            if (typeof onResponse === 'function') {
                onResponse(feedback);
            } else {
                console.error('onResponse is not a function');
            }

        } catch (error) {
            console.error('Upload error:', error);
            setUploadError('Upload failed. Please try again.');
            if (typeof onResponse === 'function') {
                onResponse({ error: 'Upload failed. Please try again.' });
            }
        } finally {
            setIsUploading(false);
            if (typeof onProgress === 'function') {
                onProgress(0); // 重置父组件中的进度
            }
        }
    };

    // 当文件被拖拽或选择时调用
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles?.length) {
            uploadFiles(acceptedFiles); // 立即开始上传
        }
    }, []);

    // 当文件被拒绝时调用
    const onDropRejected = useCallback((fileRejections) => {
        fileRejections.forEach(({ file, errors }) => {
            errors.forEach(err => {
                if (err.code === 'file-too-large') {
                    const errorMessage = `File size exceeds the limit of ${maxSize / (1024 * 1024)} MB.`;
                    setUploadError(errorMessage);
                    if (typeof onResponse === 'function') {
                        onResponse({ error: errorMessage });
                    }
                } else if (err.code === 'file-invalid-type') {
                    const errorMessage = `Invalid file type.`;
                    setUploadError(errorMessage);
                    if (typeof onResponse === 'function') {
                        onResponse({ error: errorMessage });
                    }
                } else {
                    // 处理其他错误
                    const errorMessage = err.message;
                    setUploadError(errorMessage);
                    if (typeof onResponse === 'function') {
                        onResponse({ error: errorMessage });
                    }
                }
            });
        });
    }, [onResponse, maxSize]);

    const mimeTypeMapping = {
        'audio': 'audio/*',
        'video': 'video/*',
        'slide': {
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx']
        }
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept: accept || mimeTypeMapping[pathSegment] || {},
        onDrop,
        onDropRejected,
        maxSize: maxSize || defaultMaxSize,
        noClick: true, // 禁用默认点击行为
    });

    return (
        <div>
            <div
                {...getRootProps({
                    className: `flex items-stretch ${className}`, // 使用 items-stretch
                })}
            >
                <input {...getInputProps()} />

                {/* 左侧 1/4 区域 - 选择文件按钮 */}
                <button
                    type="button"
                    onClick={open}
                    className="flex items-center justify-center w-1/4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold rounded-l"
                >
                    Choose File
                </button>

                {/* 右侧 3/4 区域 - 拖放区域 */}
                <div className="flex-1 border-dashed border-2 border-gray-300 rounded-r flex flex-col items-center justify-center p-4">
                    <FontAwesomeIcon icon={faArrowUp} className='w-5 h-5 fill-current mb-2' />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                            {instructionText || 'Drag & drop files here (Maximum file size: 20 MB)'}
                        </p>
                    )}
                </div>
            </div>

            {/* 上传错误信息 */}
            {uploadError && <p className="text-red-500 mt-2">{uploadError}</p>}
        </div>
    );
};

export default Dropzone;
