// SelectedFileContext.js
import React, { createContext, useState } from 'react';

export const SelectedFileContext = createContext();

export const SelectedFileProvider = ({ children }) => {
    const [selectedFiles, setSelectedFiles] = useState({
        video: null,
        audio: null,
        slide: null,
        model: null,
    });

    const selectFile = (fileType, file) => {
        setSelectedFiles((prevFiles) => ({ ...prevFiles, [fileType]: file }));
    };

    return (
        <SelectedFileContext.Provider value={{ selectedFiles, setSelectedFiles, selectFile }}>
            {children}
        </SelectedFileContext.Provider>
    );
};
