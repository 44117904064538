// FileManagement.jsx
import React, { useState, useEffect } from 'react';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../function/Config";

export default function FileManagement() {
    const params = useParams();
    const [activeTab, setActiveTab] = useState('audio'); // 'audio', 'video', 'slides'
    const [audioFiles, setAudioFiles] = useState([]);
    const [videoFiles, setVideoFiles] = useState([]);
    const [slideFiles, setSlideFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [mediaSrc, setMediaSrc] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // New loading state

    useEffect(() => {
        fetchAudioFiles();
        fetchVideoFiles();
        fetchSlideFiles();
    }, []);

    useEffect(() => {
        if (selectedFile) {
            fetchMediaFile(selectedFile);
        } else {
            setMediaSrc(null);
        }
    }, [selectedFile]);

    const fetchAudioFiles = () => {
        const token = sessionStorage.getItem('Token');
        fetch(`${BASE_URL}/audios/${params.userId}/audio`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setAudioFiles(data);
            })
            .catch(error => console.error('Error fetching audio files:', error));
    };

    const fetchVideoFiles = () => {
        const token = sessionStorage.getItem('Token');
        fetch(`${BASE_URL}/videos/${params.userId}/video`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setVideoFiles(data);
            })
            .catch(error => console.error('Error fetching video files:', error));
    };

    const fetchSlideFiles = () => {
        const token = sessionStorage.getItem('Token');
        fetch(`${BASE_URL}/slides/${params.userId}/slide`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setSlideFiles(data);
            })
            .catch(error => console.error('Error fetching slide files:', error));
    };

    const handleDelete = (fileType, fileId) => {
        const token = sessionStorage.getItem('Token');
        fetch(`${BASE_URL}/${fileType}/${params.userId}/delete/${fileId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.ok) {
                    // Remove the deleted file from the state
                    if (fileType === 'audios') {
                        setAudioFiles(prev => prev.filter(file => file.id !== fileId));
                    } else if (fileType === 'videos') {
                        setVideoFiles(prev => prev.filter(file => file.id !== fileId));
                    } else if (fileType === 'slides') {
                        setSlideFiles(prev => prev.filter(file => file.id !== fileId));
                    }
                    // Clear preview if the deleted file was selected
                    if (selectedFile && selectedFile.id === fileId) {
                        setSelectedFile(null);
                        setMediaSrc(null);
                    }
                } else {
                    console.error('Failed to delete file');
                }
            })
            .catch(error => console.error('Error deleting file:', error));
    };

    const fetchMediaFile = async (file) => {
        setIsLoading(true); // Start loading
        const token = sessionStorage.getItem('Token');
        let url = '';

        if (file.type === 'audios') {
            url = `${BASE_URL}/audios/${file.id}`;
        } else if (file.type === 'videos') {
            url = `${BASE_URL}/videos/${file.id}`;
        }

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch media file');
            }

            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            setMediaSrc(objectUrl);
        } catch (error) {
            console.error('Error fetching media file:', error);
            setMediaSrc(null);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    const renderFileList = (files, fileType) => (
        <ul>
            {files.map(file => (
                <li key={file.id} className="flex justify-between items-center border-b py-2">
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            setSelectedFile({ ...file, type: fileType });
                        }}
                    >
                        <p className="font-semibold">{file.filename}</p>
                        <p className="text-sm text-gray-600">Uploaded at: {new Date(file.uploaded_at).toLocaleString()}</p>
                    </div>
                    <button
                        onClick={() => handleDelete(fileType, file.id)}
                        className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Delete
                    </button>
                </li>
            ))}
        </ul>
    );

    const renderPreview = () => {
        if (!selectedFile) {
            return <p>Select a file to preview</p>;
        }

        if (selectedFile.type === 'audios') {
            return (
                <div>
                    <p className="font-semibold mb-2">{selectedFile.filename}</p>
                    <audio controls src={mediaSrc} />
                </div>
            );
        } else if (selectedFile.type === 'videos') {
            return (
                <div>
                    <p className="font-semibold mb-2">{selectedFile.filename}</p>
                    <video controls width="100%" src={mediaSrc} />
                </div>
            );
        } else if (selectedFile.type === 'slides') {
            return (
                <div>
                    <p className="font-semibold mb-2">{selectedFile.filename}</p>
                    <p>No preview available for slides.</p>
                </div>
            );
        } else {
            return <p>No preview available.</p>;
        }
    };

    return (
        <section>
            <Home_Navbar />
            <div className="flex">
                <Sidebar />
                <div className="flex-1 p-4 mt-16 ml-64">
                    <h1 className="text-2xl font-bold mb-4">File Management</h1>
                    <div className="flex">
                        <div className="w-1/3">
                            <div className="flex mb-4">
                                <button
                                    onClick={() => setActiveTab('audio')}
                                    className={`px-4 py-2 mr-2 ${activeTab === 'audio' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                >
                                    Audio Files
                                </button>
                                <button
                                    onClick={() => setActiveTab('video')}
                                    className={`px-4 py-2 mr-2 ${activeTab === 'video' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                >
                                    Video Files
                                </button>
                                <button
                                    onClick={() => setActiveTab('slides')}
                                    className={`px-4 py-2 ${activeTab === 'slides' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                >
                                    Slide Files
                                </button>
                            </div>
                            <div className="border rounded p-2 max-h-screen overflow-y-auto">
                                {activeTab === 'audio' && renderFileList(audioFiles, 'audios')}
                                {activeTab === 'video' && renderFileList(videoFiles, 'videos')}
                                {activeTab === 'slides' && renderFileList(slideFiles, 'slides')}
                            </div>
                        </div>
                        <div className="w-2/3 pl-4">
                            <h2 className="text-xl font-semibold mb-2">Preview</h2>
                            <div className="border rounded p-4 relative">
                                {isLoading && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                                        <div className="flex flex-col items-center">
                                            <svg className="animate-spin h-8 w-8 text-blue-500 mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                            </svg>
                                            <p>Loading...</p>
                                        </div>
                                    </div>
                                )}
                                {renderPreview()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
