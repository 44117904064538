// Dashboard.jsx
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";

export default function Dashboard() {
    const history = useHistory();
    const params = useParams();

    return (
        <section className="flex flex-col md:flex-row">
            <Home_Navbar />
            <Sidebar />
            <div className="flex-1 p-4 sm:ml-20 md:ml-64">
                <div className="max-w-2xl mx-auto p-4 sm:p-6 lg:p-8 mt-10 bg-white dark:bg-gray-900 rounded-lg shadow-md">
                    
                    {/* Introduction Section */}
                    <Introduction />
                    <hr className="my-6 border-gray-300 dark:border-gray-700" />

                    {/* Instruction Section
                    <Instruction />
                    <hr className="my-6 border-gray-300 dark:border-gray-700" /> */}

                    {/* FAQ Section */}
                    <FAQ />
                    
                </div>
            </div>
        </section>
    );
}

// Introduction Component
const Introduction = () => {
    return (
        <div className="text-center space-y-2">
            <p className="text-sm sm:text-base md:text-base">
                Please refer to the "How to Use?" tutorial on the upper side.
            </p>
            <p className="text-sm sm:text-base md:text-base">
                If you encounter any problems, please contact us (<a href="mailto:service.sregen.ai@gmail.com" className="text-blue-500 underline">service.sregen.ai@gmail.com</a>).
            </p>
        </div>
    );
}


// Instruction Component
const Instruction = () => {
    const steps = [
        "Customize your voice model.",
        "Customize your talking-head model.",
        "Upload your slides.",
        "Submit a lecture video task to our system and wait for an email notification."
    ];

    return (
        <div className="space-y-4">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-center">Instruction</h2>
            <ol className="list-decimal list-inside space-y-3 sm:space-y-4 text-base sm:text-lg md:text-xl">
                {steps.map((step, index) => (
                    <li key={index}>{step}</li>
                ))}
            </ol>
            {/* YouTube video */}
            <div className="relative w-full max-w-4xl mx-auto mt-6 sm:mt-8 lg:mt-10 overflow-hidden border border-gray-300 dark:border-gray-700 shadow-lg rounded-2xl pt-[56.25%]">
                <iframe
                    title="YouTube video player"
                    src="https://www.youtube.com/embed/vumo602EzlE?si=ayh_YeZekWgCOi8w" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    className="absolute top-0 left-0 w-full h-full"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
}

// FAQ Component
const FAQ = () => {
    const faqData = [
        {
            question: "Why didn't I receive an email notification when task is finished?",
            answer: "Please check your spam folder, our email may have been misclassified by the system."
        },
        {
            question: "Why is the lecture video I downloaded still a slide file?",
            answer: (
                <>
                    Please convert it into a video file according to this tutorial:{" "}
                    <a
                        href="https://support.microsoft.com/en-us/office/save-a-presentation-as-a-movie-file-or-mp4-4e1ebcc1-f46b-47b6-922a-bac76c4a5691"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                    >
                        Save a Presentation as a Movie File or MP4
                    </a>
                    .
                </>
            )
        },
        {
            question: "How to record a reference speech and talking-head video?",
            answer: (
                <>
                    You can use an {" "}
                    <a
                        href="https://webcamera.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                    >
                        online recorder
                    </a>
                    {" "}and read out a short passage aloud.
                </>
            )
        },
        // {
        //     question: "How long does it take to generate a lecture video?",
        //     answer: "The time taken to generate a lecture video depends on the length and complexity of your slides. Typically, it takes between 10 to 30 minutes."
        // }
    ];

    return (
        <div className="space-y-4">
            <h2 className="text-base sm:text-base md:text-base font-semibold text-center">FAQ</h2>
            <div className="space-y-3 sm:space-y-4">
                {faqData.map((item, index) => (
                    <FAQItem key={index} question={item.question} answer={item.answer} />
                ))}
            </div>
        </div>
    );
}

// FAQItem Component
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border border-gray-300 dark:border-gray-700 rounded-md">
            <button
                className="w-full flex items-center justify-between p-2 sm:p-3 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="flex items-center space-x-1 sm:space-x-2">
                    <span className="w-2 h-2 bg-black dark:bg-white rounded-full"></span>
                    <span className="text-sm sm:text-base md:text-base font-medium">{question}</span>
                </span>
                <svg
                    className={`w-5 h-5 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            {isOpen && (
                <div className="p-2 sm:p-3 bg-gray-50 dark:bg-gray-800">
                    <p className="text-gray-700 dark:text-gray-300 text-xs sm:text-sm md:text-base">{answer}</p>
                </div>
            )}
        </div>
    );
}

