// ResetPassword.js
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../function/Config";
import Home_Navbar from "../components/Home_Navbar";

export default function ResetPassword() {
  const { token } = useParams();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState('');

  const Submit = async (data) => {
    try {
      let url = `${BASE_URL}/auth/reset_password/${token}`;
      url = url.replace(/([^:]\/)\/+/g, "$1");

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password: data.password }),
      });

      const feedback = await response.json();

      if (response.ok) {
        setMessage(feedback.message);
      } else {
        setMessage(feedback.message || "An error occurred");
      }
    } catch (error) {
      console.error("There was an error!", error);
      setMessage("Network error or server is not responding");
    }
  };

  return (
    <>
      <Home_Navbar />
      <section className="bg-gray-200 dark:bg-gray-900 min-h-screen flex items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 p-6">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Reset Password
          </h1>
          {message && <p className="mt-4 text-sm text-green-600">{message}</p>}
          <form onSubmit={handleSubmit(Submit)} className="mt-6 space-y-4">
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                New Password
              </label>
              <input
                type="password"
                {...register("password", {
                  required: "Password is required.",
                  minLength: { value: 8, message: "Minimum length is 8 characters" },
                })}
                id="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
              {errors.password && <p className="text-xs text-red-600">{errors.password.message}</p>}
            </div>
            <button
              type="submit"
              className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Reset Password
            </button>
          </form>
        </div>
      </section>
    </>
  );
}
