// ServerStatus.jsx
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../function/Config';
import Home_Navbar from '../components/Home_Navbar';
import Sidebar from '../components/Sidebar';

export default function ServerStatus() {
  const [statusData, setStatusData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${BASE_URL}/status/tasks`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch server status');
        }
        return response.json();
      })
      .then(data => {
        setStatusData(data);
      })
      .catch(error => {
        console.error('Error fetching server status:', error);
        setError(error.message);
      });
  }, []);

  if (error) {
    return (
      <div>
        <Home_Navbar />
        <Sidebar />
        <div className="p-4 sm:ml-64">
          <h1 className="text-2xl font-bold mb-4">Server Status</h1>
          <p className="text-red-500">ERROR：{error}</p>
        </div>
      </div>
    );
  }

  if (!statusData) {
    return (
      <div>
        <Home_Navbar />
        <Sidebar />
        <div className="p-4 sm:ml-64">
          <h1 className="text-2xl font-bold mb-4">Server Status</h1>
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  const { audio_tasks, avatar_tasks, video_tasks } = statusData;

  return (
    <div>
      <Home_Navbar />
      <Sidebar />
      <div className="p-4 sm:ml-64">
        <h1 className="text-2xl font-bold mb-4">Server Status</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Audio Tasks */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Create Voice Model Tasks</h2>
            <p>Processed today:{audio_tasks.processed_today} tasks</p>
            <p>Pending:{audio_tasks.pending} tasks</p>
            <p>Estimated wait time:{audio_tasks.est} minutes</p>
          </div>
          {/* Avatar Tasks */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Create Talking-Head Model Tasks</h2>
            <p>Processed today:{avatar_tasks.processed_today} tasks</p>
            <p>Pending:{avatar_tasks.pending} tasks</p>
            <p>Estimated wait time:{avatar_tasks.est} minutes</p>
          </div>
          {/* Video Tasks */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Create Lecture Video Model Tasks</h2>
            <p>Processed today:{video_tasks.processed_today} tasks</p>
            <p>Pending:{video_tasks.pending} tasks</p>
            <p>Estimated wait time:{video_tasks.est} minutes</p>
          </div>
        </div>
      </div>
    </div>
  );
}
