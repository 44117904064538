// Audio.js
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import { SelectedFileContext } from "../components/SelectedFileContext";
import { BASE_URL } from "../function/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import SystemError from "../components/SystemError";
import { useForm } from "react-hook-form";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function Audio() {

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const params = useParams();
    const history = useHistory();

    // VideoTask
    const [selectedFile, setSelectedFile] = useState('');
    // Audio file
    const [originalFile, setOriginalFile] = useState('');

    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [filelists, setFilelists] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const filesPerPage = 5;

    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [iframeSrc, setIframeSrc] = useState('');
    const { selectedFiles, selectFile } = useContext(SelectedFileContext);
    const [modelFilter, setModelFilter] = useState('All');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [response, setResponse] = useState({});

    const select = (file) => {
        if (selectedFiles?.audio?.id === file.id) {
            selectFile('audio', null);
            setSelectedFile(null);
        } else {
            selectFile('audio', file);
            setSelectedFile(file);
        }
    };

    const handleDelete = async (file) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete the model "${file.filename}"?`);
        if (confirmDelete) {
            try {
                const token = sessionStorage.getItem('Token');
                const response = await fetch(`${BASE_URL}/audio-tasks/${params.userId}/${file.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    // 从文件列表中移除已删除的文件
                    setFiles(files.filter(f => f.id !== file.id));
                    alert('Model deleted successfully.');
                } else {
                    const errorData = await response.json();
                    alert(`Failed to delete the model: ${errorData.message}`);
                }
            } catch (error) {
                console.error('Error deleting model:', error);
                alert('An error occurred while deleting the model.');
            }
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        let url = `${BASE_URL}/audio-tasks/${params.userId}/done?exclude_deleted=true`;
        if (modelFilter !== 'All') {
            url += `&model=${modelFilter}`;
        }

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setFiles(data);
                console.log(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });

    }, [params.userId, modelFilter]); // Added modelFilter as dependency

    const onSubmit = async (data) => {

        const payload = {
            fileId: originalFile, // User-selected file ID
            language: data.language,
            speakerName: data.speakerName,
            id: params.userId,
            modelName: selectedPlan.title,
            // Other data to send...
        };

        try {
            const token = sessionStorage.getItem('Token');
            const response = await fetch(`${BASE_URL}/audio-tasks/${params.userId}/new_task`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(payload),
            });

            const feedback = await response.json();
            console.log(feedback);

            if (feedback.message === "File uploaded successfully") {
                setUploadSuccess(true)
            } else {
                console.log(feedback.message);
            }
        } catch (error) {
            console.log('Upload error:', error);
        }
    };

    const handleFileClick = (fileUrl) => {
        setIframeSrc(fileUrl);
        console.log(fileUrl);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const filters = ['All', 'Instant', 'Fine-grain', 'Diffusion-based'];
    const models = [
        {
            id: 1, title: "Instant", features: [
                "Our fastest voice cloning model. Suitable for standard accents.",
                "You need to provide 5 seconds of reference speech audio.",
                "No waiting time for the model to learn your voice characteristics.",
                "Can produce speech audio with acceptable quality."]
        },
        {
            id: 2, title: "Fine-grain", features: [
                "The better but slower voice cloning model. Suitable for speakers with accents.",
                "You need to provide more than 60 seconds of reference speech audio.",
                "You need to wait for more than 15 minutes for the model to complete learning your voice characteristics.",
                "Can produce speech audio with acceptable quality."
            ]
        },
        {
            id: 3, title: "Diffusion-based", features: [
                "Our best but slowest voice cloning model. Suitable for speakers with accents.",
                "You need to provide more than 30 seconds of reference speech audio.",
                "You need to wait for more than 30 minutes for the model to complete learning your voice characteristics.",
                "Can produce speech audio with the best quality."
            ]
        }
    ];

    const openModal = (model) => {
        setSelectedPlan(model);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (event) => {
        const selected = event.target.value;
        setOriginalFile(selected);
    };

    const handleDropzoneResponse = (response) => {
        setResponse(response);
        setUploadSuccess(true);
    };

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            {showModal && <SystemError onClose={() => setShowModal(false)} />}
            <div className="p-4 sm:ml-64">
                {isLoading ? (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-50 dark:bg-gray-800">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                        </div>
                    </div>

                ) : (
                    <div className="mx-auto mt-2">
                        {uploadSuccess && (
                            <div id="toast-success"
                                style={{
                                    width: '80%',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }}
                                className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert">
                                <div
                                    className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    <span className="sr-only">Check icon</span>
                                </div>
                                <div className="ms-3 text-sm font-normal">Upload successfully.</div>
                                <button onClick={() => setUploadSuccess(false)} type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#toast-success" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" d="M6 6l2 2m0-2L6 8" />
                                    </svg>
                                </button>
                            </div>)}

                        <div className="gap-5 mt-20">
                            <div className="flex items-center justify-center my-2">
                                <div
                                    className="w-full lg:w-3/5 flex flex-col bg-white rounded-lg shadow-lg p-5 dark:bg-gray-800 dark:border-gray-700">
                                    {/* File List Section */}
                                    <div className="overflow-auto min-h-[250px]">
                                        {/* Removed model filter button from here */}
                                        {/* File List */}
                                        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                                            {currentFiles.map((file) => (
                                                <li key={file.uuid}
                                                    className="py-2 sm:py-3 hover:bg-gray-100 dark:hover:bg-gray-700">
                                                    <div className="flex items-center justify-between">
                                                        {/* File info and model tag */}
                                                        <div className="flex items-center">
                                                            <button
                                                                onClick={() => handleFileClick(`${BASE_URL}/audio-tasks/${file.id}`)}
                                                                className="hover:text-blue-500 flex items-center">
                                                                <FontAwesomeIcon icon={faHeadphones} />
                                                                <span className="ml-2 truncate"
                                                                    style={{ maxWidth: '150px' }} title={file.filename}>
                                                                    {file.filename.length > 15 ? `${file.filename.substring(0, 15)}...` : file.filename}
                                                                </span>
                                                                <span
                                                                    className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                                                    {file.model}
                                                                </span>
                                                            </button>
                                                        </div>

                                                        {/* Select file button */}
                                                        <div>
                                                            <button onClick={() => select(file)}
                                                                className="text-indigo-600 hover:text-indigo-800">
                                                                <FontAwesomeIcon
                                                                    icon={selectedFile === file || selectedFiles?.audio?.id === file.id ? faCheckCircle : faCircle}
                                                                    className={selectedFile === file || selectedFiles?.audio?.id === file.id ? "text-green-500" : "text-gray-400"} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>


                                    {/* Divider Line */}
                                    <div className="border-t border-gray-300 dark:border-gray-700 my-1"></div>

                                    {/* 移动后的文本 */}
                                    <div className="text-center text-sm text-gray-500 my-2">
                                        If you can't find your models, please check their status at{' '}
                                        <a
                                            href={`https://demo.sregen.ai/history/${params.userId}/audio`}
                                            className="text-blue-500 underline"
                                        >
                                            history page
                                        </a>.
                                    </div>

                                    {/* Pagination and Model Filter Buttons */}
                                    <div className="flex justify-center items-center space-x-1 mt-1">
                                        {/* Pagination */}
                                        <div className="pagination flex items-center space-x-2">
                                            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}
                                                className="mx-2 px-4 py-2 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50">Previous
                                            </button>
                                            <p className="text-sm font-medium text-gray-900 dark:text-white">{currentPage}</p>
                                            <button onClick={() => paginate(currentPage + 1)}
                                                disabled={currentPage >= Math.ceil(files.length / filesPerPage)}
                                                className="mx-2 px-4 py-2 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50">Next
                                            </button>
                                        </div>
                                        {/* Model Filter Button */}
                                        <div className="relative">
                                            <button onClick={toggleDropdown}
                                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                type="button">
                                                {modelFilter} Models
                                                <svg className="w-2.5 h-2.5 ml-3" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                                </svg>
                                            </button>
                                            {dropdownOpen && (
                                                <div
                                                    className="absolute z-10 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 mt-1">
                                                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                                                        {filters.map(filter => (
                                                            <li key={filter}>
                                                                <div
                                                                    className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                                    <input id={`default-radio-${filter}`}
                                                                        type="radio" value={filter}
                                                                        name="default-radio"
                                                                        checked={modelFilter === filter}
                                                                        onChange={() => setModelFilter(filter)}
                                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                    <label htmlFor={`default-radio-${filter}`}
                                                                        className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                                                                        {filter}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Divider Line */}
                                    <div className="border-t border-gray-300 dark:border-gray-700 my-2"></div>

                                    {/* Audio Player */}
                                    <div className="flex-grow rounded-lg shadow-lg overflow-hidden mb-4 mt-2">
                                        {/* <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Audio Player</h2> */}
                                        <p className="text-sm text-gray-700 dark:text-gray-300">Click on a model name on the list to play the demo audio.</p>
                                        <AudioPlayer autoPlay src={iframeSrc} className="w-full" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>)}
            </div>
        </section>
    )
}
