// Sidebar.jsx
import React, { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faHeadphones, faVideo, faFilePowerpoint } from '@fortawesome/free-solid-svg-icons';
import { SelectedFileContext } from "./SelectedFileContext";
import { BASE_URL } from "../function/Config";

function Sidebar() {
    const { selectedFiles, setSelectedFiles } = useContext(SelectedFileContext);
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('video', selectedFiles.video.id);
            formData.append('audio', selectedFiles.audio.id);
            formData.append('slide', selectedFiles.slide.id);
            formData.append('speaker', selectedFiles.audio.filename);
            formData.append('videoName', selectedFiles.video.filename);
            formData.append('audioName', selectedFiles.audio.filename);
            formData.append('slideName', selectedFiles.slide.filename);

            // Include the models used
            formData.append('audioModel', selectedFiles.audio.model);
            formData.append('videoModel', selectedFiles.video.model);

            console.log(formData);
            const token = sessionStorage.getItem('Token');
            const response = await fetch(`${BASE_URL}/video-tasks/${params.userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST',
                body: formData,
            });

            const feedback = await response.json();
            console.log(feedback);

            if (response.ok) {
                setUploadSuccess(true);
                // Clear selected files
                setSelectedFiles({ video: null, audio: null, slide: null, model: null });

                // Hide the success message after a few seconds
                setTimeout(() => {
                    setUploadSuccess(false);
                }, 5000);
            } else {
                console.log(feedback.message);
            }
        } catch (error) {
            console.log('Upload error:', error);
        }
    };

    // Feedback Modal State Variables
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedbackSubject, setFeedbackSubject] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('');
    const [feedbackEmail, setFeedbackEmail] = useState('');
    const [feedbackError, setFeedbackError] = useState('');
    const [feedbackSuccess, setFeedbackSuccess] = useState(false);

    // Handle Feedback Submission
    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        setFeedbackError('');

        // Validate required fields
        if (!feedbackSubject || !feedbackContent) {
            setFeedbackError('Subject and Content are required.');
            return;
        }

        // Prepare data to send
        const data = {
            subject: feedbackSubject,
            content: feedbackContent,
            email: feedbackEmail,
            url: window.location.href, // Current URL
        };

        try {
            // Send data to backend
            const response = await fetch(`${BASE_URL}/feedback/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setFeedbackSuccess(true);
                // Clear form fields
                setFeedbackSubject('');
                setFeedbackContent('');
                setFeedbackEmail('');
                // Close modal after a short delay
                setTimeout(() => {
                    setShowFeedbackModal(false);
                    setFeedbackSuccess(false);
                }, 2000);
            } else {
                setFeedbackError('Failed to submit feedback. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setFeedbackError('An error occurred. Please try again.');
        }
    };

    return (
        <>
            {/* Success Message */}
            {uploadSuccess && (
                <div
                    id="toast-success"
                    className="fixed top-4 right-4 z-50 flex items-center w-11/12 max-w-xs p-4 text-gray-500 bg-green-500 rounded-lg shadow-lg"
                    role="alert"
                >
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-white bg-green-700 rounded-lg">
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 4.707 7.293a1 1 0 10-1.414 1.414L9 14.414l7.707-7.707a1 1 0 000-1.414z" />
                        </svg>
                        <span className="sr-only">Success icon</span>
                    </div>
                    <div className="ml-3 text-sm font-normal text-white">Task created successfully.</div>
                    <button
                        onClick={() => setUploadSuccess(false)}
                        type="button"
                        className="ml-auto bg-green-500 text-white hover:text-gray-200 rounded-lg p-1.5 hover:bg-green-600"
                        aria-label="Close"
                    >
                        <span className="sr-only">Close</span>
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1l12 12M13 1L1 13"
                            />
                        </svg>
                    </button>
                </div>
            )}

            {/* Sidebar Component */}
            <aside
                id="logo-sidebar"
                className="fixed top-0 left-0 w-16 sm:w-20 md:w-64 h-screen pt-20 transition-transform transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 overflow-y-auto"
                aria-label="Sidebar"
            >
                <div className="flex flex-col h-full px-2 pb-4 space-y-4">
                    <ul className="space-y-1">
                        {/* Section A */}
                        <li className="font-semibold text-sm sm:text-base md:text-lg">Voice Model</li>
                        <li
                            onClick={() => history.push(`/audio/${params.userId}`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive('/audio/') ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">My Voice Model</span>
                        </li>
                        <li
                            onClick={() => history.push(`/newaudio/${params.userId}`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive('/newaudio/') ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">Create Voice Model</span>
                        </li>
                        <div className="border-b border-gray-200 dark:border-gray-700 my-2"></div>

                        {/* Section B */}
                        <li className="font-semibold text-sm sm:text-base md:text-lg">Talking-head Model</li>
                        <li
                            onClick={() => history.push(`/video/${params.userId}`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive('/video/') ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">My Talking-head Model</span>
                        </li>
                        <li
                            onClick={() => history.push(`/newvideo/${params.userId}`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive('/newvideo/') ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">Create Talking-head Model</span>
                        </li>
                        <div className="border-b border-gray-200 dark:border-gray-700 my-2"></div>

                        {/* Section C */}
                        <li className="font-semibold text-sm sm:text-base md:text-lg">Slides</li>
                        <li
                            onClick={() => history.push(`/slide/${params.userId}`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive('/slide/') ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">My Slides</span>
                        </li>
                        <div className="border-b border-gray-200 dark:border-gray-700 my-2"></div>

                        {/* Section D */}
                        <li className="font-semibold text-sm sm:text-base md:text-lg">Account Management</li>
                        <li
                            onClick={() => history.push(`/history/${params.userId}/audio`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive(`/history/${params.userId}/audio`) ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">Voice Model Task</span>
                        </li>
                        <li
                            onClick={() => history.push(`/history/${params.userId}/avatar`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive(`/history/${params.userId}/avatar`) ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">Talking-head Model Task</span>
                        </li>
                        <li
                            onClick={() => history.push(`/history/${params.userId}/video`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive(`/history/${params.userId}/video`) ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">Lecture Video Task</span>
                        </li>
                        <li
                            onClick={() => history.push(`/filemang/${params.userId}`)}
                            className={`ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base ${
                                isActive('/filemang/') ? 'text-orange-500' : 'text-gray-600 dark:text-gray-300'
                            } hover:text-blue-500 cursor-pointer flex items-center space-x-2`}
                        >
                            <span className="truncate">Manage My Files</span>
                        </li>
                        <div className="border-b border-gray-200 dark:border-gray-700 my-2"></div>
                    </ul>

                    <div className="flex justify-center items-center mt-2">
                        <ul className="space-y-1">
                            <li className="text-center text-xs sm:text-sm md:text-base flex items-center justify-center space-x-2">
                                <FontAwesomeIcon icon={faClipboard} />
                                <span className="truncate">Lecture Video Task</span>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <ul>
                            <li className="mt-1 ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base flex items-center space-x-2">
                                <FontAwesomeIcon icon={faHeadphones} />
                                <span className="truncate">
                                    {selectedFiles.audio ? selectedFiles.audio.filename : ''}
                                </span>
                            </li>
                            <li className="mt-1 ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base flex items-center space-x-2">
                                <FontAwesomeIcon icon={faVideo} />
                                <span className="truncate">
                                    {selectedFiles.video ? selectedFiles.video.filename : ''}
                                </span>
                            </li>
                            <li className="mt-1 ml-2 sm:ml-3 md:ml-4 text-xs sm:text-sm md:text-base flex items-center space-x-2">
                                <FontAwesomeIcon icon={faFilePowerpoint} />
                                <span className="truncate">
                                    {selectedFiles.slide ? selectedFiles.slide.filename : ''}
                                </span>
                            </li>
                            <div className="mt-3 flex items-center justify-center">
                                <button
                                    className={`px-3 py-1 text-xs sm:text-sm md:text-base font-medium rounded-md shadow transition duration-300 ease-in-out ${
                                        selectedFiles.video && selectedFiles.audio && selectedFiles.slide
                                            ? 'bg-blue-500 hover:bg-blue-600 text-white hover:shadow-md'
                                            : 'bg-gray-400 text-gray-200 cursor-not-allowed'
                                    }`}
                                    onClick={handleSubmit}
                                    disabled={
                                        !selectedFiles.video || !selectedFiles.audio || !selectedFiles.slide
                                    }
                                >
                                    Submit Task
                                </button>
                            </div>
                        </ul>
                    </div>
                </div>
            </aside>

            {/* Feedback Button */}
            <button
                className="fixed bottom-4 left-4 z-50 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={() => setShowFeedbackModal(true)}
            >
                Feedback
            </button>

            {/* Feedback Modal */}
            {showFeedbackModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    {/* Overlay */}
                    <div
                        className="fixed inset-0 bg-gray-500 bg-opacity-75"
                        onClick={() => setShowFeedbackModal(false)}
                    ></div>
                    {/* Modal Content */}
                    <div className="relative bg-white w-11/12 md:w-1/2 lg:w-1/3 xl:w-1/4 p-6 rounded-lg shadow-lg z-50">
                        {/* Close Button */}
                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                            onClick={() => setShowFeedbackModal(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        {/* Modal Body */}
                        <h2 className="text-2xl font-bold mb-4">Feedback</h2>
                        {feedbackError && (
                            <p className="text-red-500 mb-2">{feedbackError}</p>
                        )}
                        {feedbackSuccess && (
                            <p className="text-green-500 mb-2">Feedback submitted successfully, thanks for your feedback!</p>
                        )}
                        <form onSubmit={handleFeedbackSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Subject <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    value={feedbackSubject}
                                    onChange={(e) => setFeedbackSubject(e.target.value)}
                                    className="w-full px-3 py-2 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Content <span className="text-red-500">*</span>
                                </label>
                                <textarea
                                    value={feedbackContent}
                                    onChange={(e) => setFeedbackContent(e.target.value)}
                                    className="w-full px-3 py-2 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    rows="5"
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Email (Optional)
                                </label>
                                <input
                                    type="email"
                                    value={feedbackEmail}
                                    onChange={(e) => setFeedbackEmail(e.target.value)}
                                    className="w-full px-3 py-2 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowFeedbackModal(false)}
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none focus:underline"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Sidebar;
