// UploadAudio.jsx
import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import { SelectedFileContext } from "../components/SelectedFileContext";
import { BASE_URL } from "../function/Config";
import SystemError from "../components/SystemError";
import Dropzone from "../components/Dropzone";
import { useForm } from "react-hook-form";

export default function UploadVideo() {

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const params = useParams();
    const history = useHistory();

    const [selectedFile, setSelectedFile] = useState('');
    const [originalFile, setOriginalFile] = useState('');

    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Upload progress
    const [uploadError, setUploadError] = useState(null);    // Upload error
    const [fileSizeError, setFileSizeError] = useState(null); // File size error

    const [files, setFiles] = useState([]);
    const [filelists, setFilelists] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const filesPerPage = 5;

    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [iframeSrc, setIframeSrc] = useState('');
    const { selectedFiles, selectFile } = useContext(SelectedFileContext);
    const [modelFilter, setModelFilter] = useState('All');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [response, setResponse] = useState({});

    const select = (file) => {
        if (selectedFiles?.video?.id === file.id) {
            selectFile('video', null);
            setSelectedFile(null);
        } else {
            selectFile('video', file);
            setSelectedFile(file);
        }
    };

    // useEffect(() => {
    //     setIsLoading(true);
    //     const token = sessionStorage.getItem('Token');
    //     var url = `${BASE_URL}/audio-tasks/${params.userId}/done`;
    //     if (modelFilter !== 'All') {
    //         url += `?model=${modelFilter}`;
    //     }

    //     fetch(url, {
    //         headers: {
    //             'Authorization': `Bearer ${token}`
    //         }
    //     })
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(data => {
    //             setFiles(data);
    //             console.log(data);
    //             setIsLoading(false);
    //         })
    //         .catch(error => {
    //             console.error('Fetch error:', error);
    //             setShowModal(true);
    //             setIsLoading(false);
    //         });
    // }, [params.userId, modelFilter]);

    // const onSubmit = async (data) => {
    //     const payload = {
    //         fileId: originalFile,
    //         language: data.language,
    //         speakerName: data.speakerName,
    //         id: params.userId,
    //         modelName: selectedPlan.title,
    //     };

    //     try {
    //         const token = sessionStorage.getItem('Token');
    //         const response = await fetch(`${BASE_URL}/audio-tasks/${params.userId}/new_task`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'application/json'
    //             },
    //             method: 'POST',
    //             body: JSON.stringify(payload),
    //         });

    //         const feedback = await response.json();
    //         console.log(feedback);

    //         if (feedback.message === "File uploaded successfully") {
    //             setUploadSuccess(true);
    //         } else {
    //             console.log(feedback.message);
    //         }
    //     } catch (error) {
    //         console.log('Upload error:', error);
    //     }
    // };

    const handleFileClick = (fileUrl) => {
        setIframeSrc(fileUrl);
        console.log(fileUrl);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const filters = ['All', 'Instant', 'Fine-grain', 'Diffusion-based'];


    const openModal = (model) => {
        setSelectedPlan(model);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (event) => {
        const selected = event.target.value;
        setOriginalFile(selected);
    };

    const handleDropzoneResponse = (response) => {
        if (response.error) {
            setFileSizeError(response.error); // Set file size error
            setUploadSuccess(false);
        } else {
            setFileSizeError(null); // Clear previous errors
            setResponse(response);
            setUploadSuccess(true);
            setUploadProgress(0); // Reset progress after upload
        }
    };

    // Close the success message after 3 seconds
    useEffect(() => {
        if (uploadSuccess) {
            const timer = setTimeout(() => {
                setUploadSuccess(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [uploadSuccess]);

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            {showModal && <SystemError onClose={() => setShowModal(false)} />}
            <div className="p-4 sm:ml-64">
                {isLoading ? (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-50 dark:bg-gray-800">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                        </div>
                    </div>

                ) : (
                    <div className="mx-auto mt-8">
                        {uploadSuccess && (
                            <div id="toast-success"
                                style={{
                                    width: '80%',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }}
                                className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert">
                                <div
                                    className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    <span className="sr-only">Check icon</span>
                                </div>
                                <div className="ms-3 text-sm font-normal"> File uploaded successfully.</div>
                                <button onClick={() => setUploadSuccess(false)} type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#toast-success" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                </button>
                            </div>)}
                        {/* Display file size or type error */}
                        {fileSizeError && (
                            <div className="text-red-500 text-center mb-4">
                                {fileSizeError}
                            </div>
                        )}
                        <div className="gap-5 mt-20">
                            <div className="text-center font-bold text-3xl my-10 rounded-lg "> Please upload your reference talking-head video:
                            </div>

                            <div className="text-left text-base mb-6 px-4">
                                In order to achieve the best synthesized video quality, please ensure the talking-head video you upload:
                                <ul className="list-disc list-inside mt-2">
                                    <li>Each frame contains your face, with no other person's face present.</li>
                                    <li>Contains only your speech in the video.</li>
                                    <li>Your face is roughly centered in the frame.</li>
                                    <li>The background won’t affect the quality of the synthesized video.</li>
                                    <li>Normal lighting conditions.</li>
                                    <li>Keeping face the camera directly.</li>
                                    <li><a href="https://www.youtube.com/watch?v=BxeqIF2PuXQ">Here is an example.</a></li>
                                </ul>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', margin: '20px 0' }}>
                            </div>


                            <div className="flex items-center justify-center flex-col gap-8">
                                <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
                                    <h2 className="font-semibold text-lg mb-4">Upload File</h2>
                                    <Dropzone
                                        onResponse={handleDropzoneResponse}
                                        onProgress={setUploadProgress} // Pass the progress update function
                                        maxSize={200 * 1024 * 1024} // 20 MB in bytes
                                        instructionText="Drag & drop files here. (Maximum file size: 200 MB; Supported file formats: .mp4, .mov, .mkv, .avi)"
                                        accept={{
                                            'video/mp4': ['.mp4'],
                                        }}
                                        className="flex justify-center items-center p-10 border-dashed border-2 border-neutral-300 rounded-lg cursor-pointer hover:border-neutral-400 transition duration-300 ease-in-out"
                                    />
                                    {uploadProgress > 0 && (
                                        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
                                        </div>
                                    )}
                                </div>


                            </div>
                        </div>
                    </div>)}
            </div>
        </section>
    )
}
