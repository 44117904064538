// Home_Navbar.js
import "../index.css";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react"; // Import useEffect
import { BASE_URL } from '../function/Config'; // Import BASE_URL if not already imported

export default function Home_Navbar() {
  const history = useHistory();
  const params = useParams();

  const [showHowToUseModal, setShowHowToUseModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState(1);

  // New state variables for server status modal
  const [showServerStatusModal, setShowServerStatusModal] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [statusError, setStatusError] = useState(null);

  const handleClick = () => {
    if (history.location.pathname === "/signin") {
      history.push(`/signup`);
    } else if (
      history.location.pathname === "/signup" ||
      history.location.pathname === "/"
    ) {
      history.push(`/signin`);
    } else {
      history.push(`/`);
    }
  };

  const buttonText =
    history.location.pathname === "/signin"
      ? "Sign up"
      : history.location.pathname === "/signup"
      ? "Sign in"
      : history.location.pathname === "/" ||
        history.location.pathname === "/about" ||
        history.location.pathname === "/contact"
      ? "Sign in"
      : "Log out";

  // Use params.userId to construct the hrefs
  const userId = params.userId;
  const logoHref =
    history.location.pathname === "/signin" ||
    history.location.pathname === "/signup" ||
    history.location.pathname === "/" ||
    !userId
      ? "/"
      : `/dashboard/${userId}`;

  const homeHref =
    history.location.pathname === "/signin" ||
    history.location.pathname === "/signup" ||
    history.location.pathname === "/" ||
    !userId
      ? "/"
      : `/dashboard/${userId}`;

  // Steps array with titles and video URLs
  const steps = [
    {
      title: "Sregen.AI Introduction.",
      videoUrl: "https://www.youtube.com/embed/unD_mL6dmVQ?si=PR7zDifw5pCKzEqI", // Fill in your video URL here
      description: "Sregen.AI is a AI-based lecture video generation system creates lecture videos using your slides and the notes you've provided, with the system presenting the content in your voice and appearance.",
    },
    {
      title: "Customize your voice model.",
      videoUrl: "https://www.youtube.com/embed/eUD47xgmwMc?si=SqTRhPQQxBOsTkZI",
      description: (
        <>
          1. Please click <strong>Create Voice Model</strong> on the left panel and follow the prompts to create your voice model.
          <br />
          2. You can view your voice model creation history on the <strong>Voice Model Task</strong> page on the left.
        </>
      ),
    },
    {
      title: "Customize your talking-head model.",
      videoUrl: "https://www.youtube.com/embed/8vuas1xiZ3w?si=7dTXNX5TKMyI5L20", // Fill in your video URL here
      description: (
        <>
          1. Please click <strong>Create Talking-head Model</strong> on the left panel and follow the prompts to create your voice model.
          <br />
          2. You can view your talking-head model creation history on the <strong>Talking-head Model Task</strong> page on the left panel.
        </>
      ),
    },
    {
      title: "Upload slides and submit a lecture video task.",
      videoUrl: "https://www.youtube.com/embed/CP32hqF45Mo?si=Ea38hsbldxhxWzDl", // Fill in your video URL here
      description: (
        <>
          1. Please click <strong>My Slides</strong> on the left panel and upload the slides.
          <br />
          2. Then select the voice model, talking-head model, and slides you want to use, and click <strong>Submit Task</strong> on the left panel.
          <br />
          3. You can view your lecture video generation history on the <strong>Lecture Video Task</strong> page on the left panel.
        </>
      ),
    },
  ];

  // Fetch server status data when the modal is opened
  useEffect(() => {
    if (showServerStatusModal) {
      // Fetch the server status data
      fetch(`${BASE_URL}/status/tasks`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch server status");
          }
          return response.json();
        })
        .then((data) => {
          setStatusData(data);
          setStatusError(null);
        })
        .catch((error) => {
          console.error("Error fetching server status:", error);
          setStatusError(error.message);
        });
    }
  }, [showServerStatusModal]);

  return (
    <section>
      <nav className="fixed z-20 bg-white dark:bg-gray-900 w-full top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href={logoHref} className="flex items-center">
            <img
              src="/logo_black.png"
              className="h-10 md:h-12 mr-3"
              alt="Sregen.Ai Logo"
            />
          </a>
          <div className="flex md:order-2">
            <button
              onClick={handleClick}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm md:text-base px-4 md:px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transition-colors duration-200"
            >
              {buttonText}
            </button>

            {/* New Button to Open Server Status Modal */}
            <button
              onClick={() => setShowServerStatusModal(true)}
              type="button"
              className="ml-4 text-gray-800 hover:text-blue-700 dark:text-white dark:hover:text-blue-500 transition-colors duration-200"
            >
              Server Status
            </button>

            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 transition-colors duration-200"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path stroke="currentColor" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>
          </div>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
<ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href={homeHref}
                  className="block py-2 pl-3 pr-4 text-base md:text-lg text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 transition-colors duration-200"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="https://sregen.ai/"
                  className="block py-2 pl-3 pr-4 text-base md:text-lg text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 transition-colors duration-200"
                >
                  About Us
                </a>
              </li>
              {/* <li>
                <a
                  href="https://forms.gle/3UTm1LumCs2AXeJc9"
                  className="block py-2 pl-3 pr-4 text-base md:text-lg text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 transition-colors duration-200"
                >
                  Feedback
                </a>
              </li> */}
              <li>
                <button
                  onClick={() => setShowHowToUseModal(true)}
                  className="block py-2 pl-3 pr-4 text-base md:text-lg text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 transition-colors duration-200"
                >
                  How to Use?
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Modal */}
      {showHowToUseModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75"
            onClick={() => setShowHowToUseModal(false)}
          ></div>
          {/* Modal Content */}
          <div className="relative bg-white w-4/5 h-4/5 overflow-auto rounded-lg shadow-lg z-50">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowHowToUseModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Modal Body */}
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-4">How to Use</h2>
              {/* Steps */}
              <div className="flex flex-col md:flex-row">
                {/* Step List */}
                <div className="md:w-1/4 mb-4 md:mb-0">
                  <ul className="space-y-2">
                    {steps.map((step, index) => (
                      <li key={index}>
                        <button
                          onClick={() => setSelectedStep(index + 1)}
                          className={`w-full text-left py-2 px-4 rounded-lg ${
                            selectedStep === index + 1
                              ? "bg-blue-500 text-white"
                              : "bg-gray-100 text-gray-800"
                          }`}
                        >
                          Step {index + 1}: {steps[index].title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Step Content */}
                <div className="md:w-3/4 md:pl-6">
                  {/* <h3 className="text-xl font-semibold mb-2">
                    Step {selectedStep}: {steps[selectedStep - 1].title}
                  </h3> */}
                  {/* Placeholder for step description */}
                  <p className="mb-4">
                    {/* You can replace this text with the actual description for each step */}
                    {" "}
                    {steps[selectedStep - 1].description}
                  </p>
                  {/* Video */}
                  <div className="aspect-video w-3/4 mx-auto">
                    <iframe
                      className="w-full h-full"
                      src={steps[selectedStep - 1].videoUrl}
                      title={`Step ${selectedStep} Video`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Server Status Modal */}
      {showServerStatusModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75"
            onClick={() => setShowServerStatusModal(false)}
          ></div>
          {/* Modal Content */}
          <div className="relative bg-white w-4/5 h-4/5 overflow-auto rounded-lg shadow-lg z-50 p-6">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowServerStatusModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Modal Body */}
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-4">Server Status</h2>
              {statusError && (
                <p className="text-red-500">Error: {statusError}</p>
              )}
              {!statusData && !statusError && <p>Loading...</p>}
              {statusData && (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {/* Audio Tasks */}
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-2">Audio Tasks</h2>
                    <p>Processed Today: {statusData.audio_tasks.processed_today} tasks</p>
                    <p>Pending: {statusData.audio_tasks.pending} tasks</p>
                    {/* <p>Estimated wait time:{statusData.audio_task.est} minutes</p> */}
                  </div>
                  {/* Avatar Tasks */}
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-2">Avatar Tasks</h2>
                    <p>Processed Today: {statusData.avatar_tasks.processed_today} tasks</p>
                    <p>Pending: {statusData.avatar_tasks.pending} tasks</p>
                    {/* <p>Estimated wait time:{statusData.avatar_tasks.est} minutes</p> */}
                  </div>
                  {/* Video Tasks */}
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-2">Video Tasks</h2>
                    <p>Processed Today: {statusData.video_tasks.processed_today} tasks</p>
                    <p>Pending: {statusData.video_tasks.pending} tasks</p>
                    {/* <p>Estimated wait time:{statusData.video_tasks.est} minutes</p> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
