// AvatarTasksHistory.jsx
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import SystemError from "../components/SystemError";
import Modal from '../components/Modal';  // 导入 Modal 组件
import { BASE_URL } from "../function/Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


export default function AvatarTasksHistory() {
    const history = useHistory();
    const { userId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [avatarTasks, setAvatarTasks] = useState([]);

    // 用于控制状态对话框的显示和内容
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [statusModalContent, setStatusModalContent] = useState('');

    const handleDelete = async (taskId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this task?");
        if (confirmDelete) {
          try {
            const token = sessionStorage.getItem('Token');
            const response = await fetch(`${BASE_URL}/avatar-tasks/${userId}/${taskId}`, {
              method: 'DELETE',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
      
            if (response.ok) {
              // Update state to remove the deleted task
              setAvatarTasks(avatarTasks.map(task =>
                task.id === taskId ? { ...task, status: 'deleted' } : task
              ));
              alert('Task deleted successfully.');
            } else {
              const errorData = await response.json();
              alert(`Failed to delete the task: ${errorData.message}`);
            }
          } catch (error) {
            console.error('Error deleting task:', error);
            alert('An error occurred while deleting the task.');
          }
        }
      };

    const fetchAvatarTasks = () => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        const url = `${BASE_URL}/avatar-tasks/${userId}/all`;

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAvatarTasks(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });
    };

    const formatDate = (isoString) => {
        return new Date(isoString).toLocaleDateString('en-CA');
    };

    useEffect(() => {
        fetchAvatarTasks();
    }, []);

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            {showModal && <SystemError onClose={() => setShowModal(false)} />}
            {/* 状态对话框 */}
            <Modal
                isOpen={isStatusModalOpen}
                onClose={() => setIsStatusModalOpen(false)}
                title="Task Status Details"
            >
                <p>{statusModalContent}</p>
            </Modal>

            <div className="p-4 sm:ml-64">
                <div className="flex flex-wrap justify-center items-center mt-20">
                    <div className="relative overflow-x-auto shadow-md w-full max-w-4xl mx-auto">
                        <h2 className="text-2xl font-bold mb-4 text-center">Talking-head Model Tasks History</h2>
                        <table className="w-full text-sm text-center text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Speaker</th>
                                    <th scope="col" className="px-6 py-3">Language</th>
                                    <th scope="col" className="px-6 py-3">Model</th>
                                    <th scope="col" className="px-6 py-3">Status</th>
                                    <th scope="col" className="px-6 py-3">Created At</th>
                                    <th scope="col" className="px-6 py-3">Delete</th> {/* 新增列 */}
                                </tr>
                            </thead>
                            <tbody>
                                {avatarTasks.map(task => {
                                    // 确定状态显示
                                    let displayStatus = '';
                                    let textColorClass = '';
                                    let showIcon = false;
                                    let statusMessage = '';

                                    if (task.status === 'done') {
                                        displayStatus = 'Finished';
                                        textColorClass = 'text-green-600';
                                    } else if (task.status === 'deleted') {
                                        displayStatus = 'Deleted';
                                        textColorClass = 'text-gray-600';
                                    } else if (task.status.startsWith('error:')) {
                                        displayStatus = 'Error';
                                        textColorClass = 'text-red-600';
                                        showIcon = true;
                                        statusMessage = task.status.substring('error:'.length).trim();
                                    } else if (task.status.startsWith('warning:')) {
                                        displayStatus = 'Warning';
                                        textColorClass = 'text-yellow-600';
                                        showIcon = true;
                                        statusMessage = task.status.substring('warning:'.length).trim();
                                    } else if (task.status === 'pending') {
                                        displayStatus = 'Pending';
                                        textColorClass = 'text-blue-600';
                                    } else {
                                        displayStatus = 'Unknown';
                                        textColorClass = 'text-gray-600';
                                        showIcon = true;
                                        statusMessage = task.status.trim();
                                    }

                                    const handleIconClick = () => {
                                        if (showIcon) {
                                            setStatusModalContent(statusMessage);
                                            setIsStatusModalOpen(true);
                                        }
                                    };

                                    return (
                                        <tr key={task.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-4">{task.filename}</td>
                                            <td className="px-6 py-4">{task.language}</td>
                                            <td className="px-6 py-4">{task.model}</td>
                                            <td className="px-6 py-4">
                                                <span className={`font-medium ${textColorClass}`}>
                                                    {displayStatus}
                                                    {showIcon && (
                                                        <span
                                                            className="ml-1 cursor-pointer inline-block"
                                                            onClick={handleIconClick}
                                                        >
                                                            {/* 'i' 图标 */}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4 w-4 inline-block"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M13 16h-1v-4h-1m1-4h.01M12 8v.01"
                                                                />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4">{formatDate(task.created_at)}</td>
                                            <td>
                                                {task.status !== 'deleted' && (
                                                    <button
                                                    onClick={() => handleDelete(task.id)}
                                                    className="text-red-600 hover:text-red-800"
                                                    >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {isLoading && (
                            <div className="text-center mt-4">Loading...</div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
