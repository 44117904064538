// NewAudio.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Home_Navbar from "../components/Home_Navbar";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ModelCard from "../components/ModelCard";
import Dropzone from "../components/Dropzone";
import { BASE_URL } from "../function/Config";
import { ReactMediaRecorder } from 'react-media-recorder';

// Tooltip Component
const Tooltip = ({ tooltipText }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <span className="relative inline-block ml-1">
      <span
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className="inline-flex items-center justify-center w-4 h-4 bg-gray-300 text-gray-800 rounded-full cursor-pointer text-xs font-bold"
        aria-label="Information"
      >
        !
      </span>
      {visible && (
        <div
          ref={tooltipRef}
          className="absolute z-10 p-2 mt-1 text-white bg-gray-700 rounded-md shadow-lg max-h-sm"
        >
          <ul className="list-disc pl-4 space-y-2 leading-relaxed">
            {tooltipText.map((line, index) => (
              <li key={index}>{line}</li>
            ))}
          </ul>
        </div>
      )}
    </span>
  );
};

export default function NewAudio() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
  });
  const params = useParams();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [originalFile, setOriginalFile] = useState('');
  const [filelists, setFilelists] = useState([]);

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [showDeprecatedModels, setShowDeprecatedModels] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 4;

  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileSizeError, setFileSizeError] = useState(null);

  const [existingSpeakers, setExistingSpeakers] = useState([]);
  const [speakerOption, setSpeakerOption] = useState('new'); // 'new' or 'existing'

  const language = watch('language');
  const speakerName = watch('speakerName');
  const existingSpeaker = watch('existingSpeaker');

  const [activeTab, setActiveTab] = useState('select'); // 'select', 'upload', 'record'
  const [recordedFileName, setRecordedFileName] = useState('');
  const [promptText] = useState(
    "These recordings will only be used to create a copy of your voice and language. Your voice data will not be used for anything else or shared with anyone else. We take your privacy and data security very seriously. All recordings are kept safe and used only for your personal needs. If you have any questions or need more information, please contact our customer support team. Thank you for your understanding and help."
  );

  const [recordedBlob, setRecordedBlob] = useState(null); // State to store the recorded blob
  const [isLoading, setIsLoading] = useState(false); // State for loading overlay
  const [previewUrl, setPreviewUrl] = useState(null); // State for audio preview URL

  useEffect(() => {
    if (uploadSuccess) {
      setCurrentStep(1);
      setSelectedPlan(null);
      reset();
      setOriginalFile('');
    }
  }, [uploadSuccess, reset]);

  useEffect(() => {
    if (uploadSuccess) {
      const timer = setTimeout(() => {
        setUploadSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (fileUploadSuccess) {
      const timer = setTimeout(() => {
        setFileUploadSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [fileUploadSuccess]);

  // Fetch file list when userId changes
  useEffect(() => {
    if (params.userId) {
      fetchFileList();
      fetchExistingSpeakers();
    }
  }, [params.userId]);

  // Fetch file list when uploadedFileName changes
  useEffect(() => {
    if (uploadedFileName !== null) {
      // Delay to allow backend to process the new file
      setTimeout(() => {
        fetchFileList();
      }, 500);
    }
  }, [uploadedFileName]);

  const fetchFileList = () => {
    const token = sessionStorage.getItem('Token');
    fetch(`${BASE_URL}/audios/${params.userId}/audio`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setFilelists(data);
        if (uploadedFileName) {
          const uploadedFile = data.find(file => file.filename.trim().toLowerCase() === uploadedFileName.trim().toLowerCase());
          if (uploadedFile) {
            setOriginalFile(uploadedFile.id.toString());
          }
          setUploadedFileName(null);
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  };

  // Fetch existing speakers from avatar tasks (talking-head models)
  const fetchExistingSpeakers = () => {
    const token = sessionStorage.getItem('Token');
    fetch(`${BASE_URL}/avatar-tasks/${params.userId}/all`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const speakers = data.map(task => task.filename);
        const uniqueSpeakers = Array.from(new Set(speakers));
        setExistingSpeakers(uniqueSpeakers);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  };

  const handleRecordedFileNameChange = (e) => {
    setRecordedFileName(e.target.value);
  };

  const handleDropzoneResponse = (response) => {
    if (response.error) {
      setFileSizeError(response.error);
      setFileUploadSuccess(false);
    } else {
      setFileSizeError(null);
      setFileUploadSuccess(true);
      setUploadProgress(0);
      // Store the uploaded filename
      let newUploadedFileName = '';

      // Check if response contains the filename
      if (response.files && response.files.length > 0) {
        newUploadedFileName = response.files[0].filename;
        const newFileId = response.files[0].id;
        setOriginalFile(newFileId.toString());
        setUploadedFileName(newUploadedFileName);
        setFilelists([...filelists, response.files[0]]);
        setActiveTab('select');
        alert('File uploaded successfully.');
      } else if (response.filename) {
        newUploadedFileName = response.filename;
      } else if (response.file && response.file.filename) {
        newUploadedFileName = response.file.filename;
      } else {
        console.error('Filename not found in response:', response);
        return;
      }
    }
  };

  const onSubmit = async (data) => {
    let processedSpeakerName = '';

    if (speakerOption === 'new') {
      processedSpeakerName = data.speakerName.replace(/[\s\-]/g, '_');
    } else if (speakerOption === 'existing') {
      processedSpeakerName = data.existingSpeaker.replace(/[\s\-]/g, '_');
    }

    const payload = {
      language: data.language,
      speakerName: processedSpeakerName,
      id: params.userId,
      modelName: selectedPlan.title,
      fileId: originalFile,
    };

    try {
      const token = sessionStorage.getItem('Token');
      const response = await fetch(`${BASE_URL}/audio-tasks/${params.userId}/new_task`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });

      const feedback = await response.json();

      if (feedback.message === "Task created successfully") {
        setUploadSuccess(true);
        // Reset steps and selections
        setCurrentStep(1);
        setSelectedPlan(null);
        reset();
        setOriginalFile('');
        setRecordedFileName('');
      } else {
        console.log("Failed to create task:", feedback.message);
      }
    } catch (error) {
      console.log('Error creating task:', error);
    }
  };

  // Define the models with tooltips for each feature
  const commonModels = [
    {
      id: 1,
      title: "Instant",
      features: [
        {
          text: "Speech Naturalness: ★★★★☆",
          tooltip: ""
        },
        {
          text: "Speech Voice Similarity: ★★★☆☆",
          tooltip: ""
        },
        {
          text: "Training Time: 0 Minute",
          tooltip: "No training time required; ready to use instantly."
        },
        {
          text: "Synthesizing Speed: ~ 50 words / second",
          tooltip: ""
        },
        {
          text: "Email Notification: No",
          tooltip: "You will not get an email notification after the task completes."
        }
      ]
    },
    {
      id: 3,
      title: "Diffusion-based",
      features: [
        {
          text: "Speech Naturalness: ★★★★☆",
          tooltip: ""
        },
        {
          text: "Speech Voice Similarity: ★★★★★",
          tooltip: ""
        },
        {
          text: "Training Time: 15 Minutes",
          tooltip: "Requires 15 minutes of training before use."
        },
        {
          text: "Synthesizing Speed: ~ 30 words / second",
          tooltip: ""
        },
        {
          text: "Email Notification: Yes",
          tooltip: "You will get an email notification after the task completes."
        }
      ]
    }
  ];

  const deprecatedModels = [
    {
      id: 2,
      title: "Fine-grain",
      features: [
        {
          text: "Speech Naturalness: ★★★★☆",
          tooltip: ""
        },
        {
          text: "Speech Voice Similarity: ★★★★☆",
          tooltip: ""
        },
        {
          text: "Training Time: 30 Minutes",
          tooltip: ""
        },
        {
          text: "Synthesizing Speed: ~ 40 words / second",
          tooltip: ""
        },
        {
          text: "Email Notification: Yes",
          tooltip: "You will get an email notification after the task completes."
        }
      ]
    }
  ];

  const handleChange = (event) => {
    const selected = event.target.value;
    setOriginalFile(selected);
  };

  const proceedToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const selectModel = (model) => {
    setSelectedPlan(model);
    setCurrentStep(2);
  };

  const selectedSpeakerName = speakerOption === 'new' ? watch('speakerName') : watch('existingSpeaker');

  const isSpeakerSelected = (speakerOption === 'new' && watch('speakerName')) || (speakerOption === 'existing' && watch('existingSpeaker'));

  // Handle file click in the file list
  const handleFileClick = (file) => {
    setOriginalFile(file.id.toString());
    downloadAndPreviewFile(file.id);
  };

  // Download and preview the selected file
  const downloadAndPreviewFile = async (fileId) => {
    setIsLoading(true);
    setPreviewUrl(null);
    try {
      const token = sessionStorage.getItem('Token');
      const response = await fetch(`${BASE_URL}/audios/${fileId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setPreviewUrl(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <Home_Navbar />
      <Sidebar />
      <div className="p-4 sm:ml-64">
        {/* Loading Overlay */}
        {isLoading && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
            </svg>
          </div>
        )}
        <div className="mx-auto mt-8">
          {uploadSuccess && (
            <div id="toast-success"
              style={{
                width: '80%',
                backgroundColor: '#4CAF50',
                color: 'white',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
              }}
              className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 rounded-lg shadow"
              role="alert">
              <div
                className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-white bg-green-500 rounded-lg">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 5.707 8.293a1 1 0 00-1.414 1.414L9 14.414l8.707-8.707a1 1 0 000-1.414z" />
                </svg>
                <span className="sr-only">Check icon</span>
              </div>
              <div className="ml-3 text-sm font-normal"> Task created successfully.</div>
              <button onClick={() => setUploadSuccess(false)} type="button"
                className="ml-auto -mx-1.5 -my-1.5 bg-green-500 text-white hover:text-gray-200 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-green-600 inline-flex items-center justify-center h-8 w-8"
                data-dismiss-target="#toast-success" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" d="M1 1l12 12M13 1L1 13" />
                </svg>
              </button>
            </div>
          )}
          {fileUploadSuccess && (
            <div className="text-green-500 mb-4">
              File uploaded successfully.
            </div>
          )}
        </div>

        <div className="flex flex-col items-center">
          {/* Step Indicator */}
          <div className="w-full max-w-3xl mt-10">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${(currentStep / totalSteps) * 100}%` }}></div>
            </div>
            <div className="flex justify-between text-sm mt-2">
              <span>Step {currentStep}</span>
              <span>{totalSteps} Steps</span>
            </div>
          </div>

          {/* Display Selected Information */}
          {selectedPlan && (
            <div className="w-full max-w-3xl mt-6 bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-2">Selected Model: {selectedPlan.title}</h3>
              {language && (
                <p><strong>Language:</strong> {language}</p>
              )}
              {selectedSpeakerName && (
                <p><strong>Speaker Name:</strong> {selectedSpeakerName}</p>
              )}
            </div>
          )}
          {/* Steps */}
          {currentStep === 1 && (
            <>
              <p className="text-4xl font-bold text-center mt-10">Please choose the base model you want to use:</p>

              {/* Common Models */}
              <div className="flex justify-center items-center mt-10">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mx-auto">
                  {commonModels.map(model => (
                    <ModelCard
                      key={model.id}
                      title={model.title}
                      features={model.features}
                      onChoose={() => selectModel(model)}
                    />
                  ))}
                </div>
              </div>

              {/* Toggle Button for Deprecated Models */}
              <div className="flex justify-center mt-10">
                <button
                  onClick={() => setShowDeprecatedModels(!showDeprecatedModels)}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                >
                  {showDeprecatedModels ? "Hide Deprecated Models" : "Show Deprecated Models"}
                </button>
              </div>

              {/* Deprecated Models */}
              {showDeprecatedModels && (
                <div className="flex justify-center items-center mt-10">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mx-auto">
                    {deprecatedModels.map(model => (
                      <ModelCard
                        key={model.id}
                        title={model.title}
                        features={model.features}
                        onChoose={() => selectModel(model)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          {currentStep > 1 && (
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-3xl mt-10 bg-white p-6 rounded-lg shadow-md">
              {/* Step 2 */}
              {currentStep === 2 && (
                <div>
                  <h3 className="text-lg font-semibold mb-4">Step 2: Please select the language</h3>
                  {errors.language &&
                    <p className="text-red-600">{errors.language.message}</p>}
                  <select {...register("language", { required: "This field is required" })}
                    className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                    <option value="">Select Language</option>
                    {['English'].map((lang) => (
                      <option key={lang} value={lang}>{lang}</option>
                    ))}
                  </select>
                </div>
              )}
              {/* Step 3 */}
              {currentStep === 3 && (
                <div>
                  <h3 className="text-lg font-semibold mb-4">Step 3: Please enter the name of the speaker</h3>

                  <div className="mb-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="speakerOption"
                        value="new"
                        checked={speakerOption === 'new'}
                        onChange={() => {
                          setSpeakerOption('new');
                          setValue('existingSpeaker', '');
                          clearErrors('existingSpeaker');
                        }}
                      />
                      <span className="ml-2">Enter New Speaker Name</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        type="radio"
                        className="form-radio"
                        name="speakerOption"
                        value="existing"
                        checked={speakerOption === 'existing'}
                        onChange={() => {
                          setSpeakerOption('existing');
                          setValue('speakerName', '');
                          clearErrors('speakerName');
                        }}
                      />
                      <span className="ml-2">Select Existing Speaker From Talking-head Models</span>
                    </label>
                  </div>

                  {speakerOption === 'new' && (
                    <div>
                      {errors.speakerName &&
                        <p className="text-red-600">{errors.speakerName.message}</p>}
                      <input {...register("speakerName", {
                        required: speakerOption === 'new' ? "This field is required" : false,
                      })}
                        className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        type="text" />
                    </div>
                  )}

                  {speakerOption === 'existing' && (
                    <div>
                      {errors.existingSpeaker &&
                        <p className="text-red-600">{errors.existingSpeaker.message}</p>}
                      <select {...register("existingSpeaker", {
                        required: speakerOption === 'existing' ? "This field is required" : false,
                      })}
                        className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                        <option value="">Select Speaker</option>
                        {existingSpeakers.map((speaker, index) => (
                          <option key={index} value={speaker}>{speaker}</option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )}
              {/* Step 4 */}
              {currentStep === 4 && (
                <div>
                  <h3 className="text-lg font-semibold mb-4">Step 4: Please select an audio file</h3>
                  <div className="mb-4">
                    <div className="flex border-b">
                      <button
                        type="button" // Add this line
                        className={`py-2 px-4 ${activeTab === 'select' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                        onClick={() => setActiveTab('select')}
                      >
                        Select a file
                      </button>
                      <button
                        type="button" // Add this line
                        className={`py-2 px-4 ${activeTab === 'upload' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                        onClick={() => setActiveTab('upload')}
                      >
                        Upload a new file
                      </button>
                      <button
                        type="button" // Add this line
                        className={`py-2 px-4 ${activeTab === 'record' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                        onClick={() => setActiveTab('record')}
                      >
                        Record audio
                      </button>
                    </div>
                  </div>

                  {activeTab === 'select' && (
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-bold text-gray-700">
                        Select an uploaded file:
                      </label>
                      <div
                        className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline overflow-y-auto"
                        style={{ maxHeight: '200px' }}
                      >
                        {filelists.length > 0 ? (
                          <ul>
                            {filelists.map((file, index) => (
                              <li
                                key={index}
                                onClick={() => handleFileClick(file)}
                                className={`cursor-pointer p-2 hover:bg-gray-200 ${originalFile === file.id.toString() ? 'bg-gray-300' : ''}`}
                              >
                                {file.filename}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No files available.</p>
                        )}
                      </div>
                      {/* Audio Preview */}
                      {previewUrl && (
                        <div className="mb-4">
                          <audio controls src={previewUrl} className="w-full"></audio>
                        </div>
                      )}
                    </div>
                  )}

                  {activeTab === 'upload' && (
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-bold text-gray-700">
                        Upload a new file (Please ensure it meets the specifications:
                        <Tooltip tooltipText={[
                          "Contains minimal background noise.",
                          "Only contains your speech."
                        ]} />
                        ):
                      </label>
                      <div className="bg-white shadow-md rounded-lg p-6 w-full">
                        <Dropzone
                          onResponse={handleDropzoneResponse}
                          onProgress={setUploadProgress}
                          maxSize={20 * 1024 * 1024}
                          instructionText="Drag & drop files here. (Maximum file size: 20 MB; Supported file format: .wav|.mp3|.flac|.acc)"
                          accept={{
                            'audio/wav': ['.wav'],
                            'audio/mp3': ['.mp3'],
                            'audio/flac': ['.flac'],
                            'audio/acc': ['.acc'],
                          }}
                          className="flex justify-center items-center p-10 border-dashed border-2 border-neutral-300 rounded-lg cursor-pointer hover:border-neutral-400 transition duration-300 ease-in-out"
                        />
                        {uploadProgress > 0 && (
                          <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === 'record' && (
                    <div className="mb-4">
                      <p className="mb-2 text-sm font-bold text-gray-700">
                        Please read the following text aloud:
                      </p>
                      <p className="mb-4 italic text-gray-600">
                        "{promptText}"
                      </p>
                      <ReactMediaRecorder
                        audio
                        onStop={(blobUrl, blob) => {
                          setRecordedBlob(blob);
                          // Generate default filename (timestamp)
                          const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
                          setRecordedFileName(`recording_${timestamp}.wav`);
                        }}
                        render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
                          <div>
                            <div className="flex items-center mb-4">
                              <button
                                type="button"
                                onClick={() => {
                                  setRecordedBlob(null); // Reset the recorded blob
                                  setRecordedFileName(''); // Reset the filename
                                  startRecording();
                                }}
                                disabled={status === "recording"}
                                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 mr-4"
                              >
                                Start Recording
                              </button>
                              <button
                                type="button"
                                onClick={stopRecording}
                                disabled={status !== "recording"}
                                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                              >
                                Stop Recording
                              </button>
                              <span className="ml-4">{status}</span>
                            </div>
                            {mediaBlobUrl && (
                              <div className="mt-4">
                                <p className="mb-2 text-sm font-bold text-gray-700">
                                  Recording completed. Please enter a filename:
                                </p>
                                <input
                                  type="text"
                                  value={recordedFileName}
                                  onChange={handleRecordedFileNameChange}
                                  className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                />
                                <audio controls src={mediaBlobUrl} className="w-full" />
                                <button
                                  type="button"
                                  onClick={async () => {
                                    if (!recordedBlob) {
                                      alert('No recording found. Please record audio first.');
                                      return;
                                    }

                                    const formData = new FormData();
                                    formData.append('file', recordedBlob, recordedFileName);

                                    try {
                                      const token = sessionStorage.getItem('Token');
                                      const uploadResponse = await fetch(`${BASE_URL}/audios/${params.userId}/upload`, {
                                        method: 'POST',
                                        headers: {
                                          'Authorization': `Bearer ${token}`,
                                        },
                                        body: formData,
                                      });

                                      const uploadResult = await uploadResponse.json();
                                      console.log('Upload Result:', uploadResult);
                                      if (uploadResponse.ok) {
                                        if (uploadResult.files && uploadResult.files.length > 0) {
                                          const file = uploadResult.files[0];
                                          if (file.id) {
                                            const newFileId = file.id;
                                            setOriginalFile(newFileId.toString());
                                            setUploadedFileName(file.filename);
                                            setFilelists([...filelists, file]);
                                            setActiveTab('select');
                                            alert('Recording uploaded successfully.');
                                          } else {
                                            console.error('File ID is missing in the upload response.');
                                            alert('Failed to upload the recorded file. Please try again.');
                                          }
                                        } else {
                                          alert('Failed to upload the recorded file. Please try again.');
                                        }
                                      } else {
                                        alert('Failed to upload the recorded file. Please try again.');
                                      }
                                    } catch (error) {
                                      console.error('Error uploading recorded file:', error);
                                      alert('Failed to upload the recorded file. Please try again.');
                                    }
                                  }}
                                  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                >
                                  Upload Recording
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
              {/* Navigation Buttons */}
              <div className="flex justify-between mt-6">
                {currentStep > 2 && (
                  <button
                    type="button"
                    onClick={() => setCurrentStep(currentStep - 1)}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                  >
                    Previous
                  </button>
                )}
                {currentStep < totalSteps && (
                  <button
                    type="button"
                    onClick={() => setCurrentStep(currentStep + 1)}
                    className="ml-auto px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    disabled={
                      (currentStep === 2 && !language) ||
                      (currentStep === 3 && !isSpeakerSelected)
                    }
                  >
                    Next
                  </button>
                )}
                {currentStep === totalSteps && activeTab === 'select' && (
                  <button type="submit"
                    className="ml-auto px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                    disabled={!originalFile}
                  >
                    Create
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
}
