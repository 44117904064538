// ModelCard.jsx
import React, { useState, useRef, useEffect } from 'react';

function ModelCard({ title, features, onChoose }) {
    // Function to highlight key information within feature text
    const highlightFeature = (featureText) => {
        // Define the patterns and their corresponding styles
        const patterns = [
            { regex: /(fastest|better|best|premium|natural lip|body|more refined)/gi, className: 'text-green-500' },
            { regex: /(more than \d+ seconds|around \d+ minutes|seconds)/gi, className: 'text-blue-600' },
            { regex: /(no waiting time|acceptable quality|best quality|takes \d+ minutes)/gi, className: 'text-purple-600' },
            { regex: /(standard accents|speakers with accents)/gi, className: 'text-red-500' },
        ];

        // Split the text based on the patterns and apply styles
        let parts = [featureText];
        patterns.forEach(({ regex, className }) => {
            const temp = [];
            parts.forEach(part => {
                if (typeof part === 'string') {
                    const matches = part.split(regex);
                    matches.forEach((segment, index) => {
                        if (regex.test(segment)) {
                            temp.push(<strong key={index} className={className}>{segment}</strong>);
                        } else {
                            temp.push(segment);
                        }
                    });
                } else {
                    temp.push(part);
                }
            });
            parts = temp;
        });

        return parts;
    };

    return (
        <div className="max-w-sm p-4 bg-white border-2 border-gray-300 rounded-lg shadow-xl overflow-visible">
            <h5 className="text-2xl font-semibold text-gray-700 mb-4 text-center">{title}</h5>
            <ul className="list-disc list-inside space-y-2 mb-4">
                {features.map((feature, index) => (
                    <FeatureItem key={index} feature={feature} highlightFeature={highlightFeature} />
                ))}
            </ul>
            <button
                onClick={onChoose}
                className="w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition ease-in-out duration-300"
            >
                Choose Plan
            </button>
        </div>
    );
}

function FeatureItem({ feature, highlightFeature }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

    // Close tooltip if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <li className="flex items-center">
            <span className="text-gray-600">{highlightFeature(feature.text)}</span>
            {feature.tooltip && feature.tooltip.trim() !== "" && (
                <div className="relative ml-2">
                    <span
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        className="inline-flex items-center justify-center w-5 h-5 bg-gray-200 text-gray-700 rounded-full text-xs cursor-pointer hover:bg-gray-300"
                    >
                        !
                    </span>
                    {showTooltip && (
                        <div
                            ref={tooltipRef}
                            className="absolute z-50 p-2 rounded bg-gray-800 text-white text-sm w-48"
                            style={{
                                top: '100%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                marginTop: '0.5rem',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                            }}
                        >
                            {feature.tooltip}
                        </div>
                    )}
                </div>
            )}
        </li>
    );
}

export default ModelCard;
